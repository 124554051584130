




























import { promiseHelper } from '@/helper/promise-helper'
import { ProfileModel } from '@/models/profile-model'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { last, uniq, uniqBy } from 'lodash'
import { autorun, IReactionDisposer, reaction, toJS } from 'mobx'
import { Observer } from 'mobx-vue'
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { nextTick } from 'vue/types/umd'

@Observer
@Component({
  components: {},
})
export default class PostComment extends Vue {
  @Prop() postStore!: PostStore
  @PropSync('value', { default: '' }) valueSync!: string
  @Ref('editable') input

  disposers: IReactionDisposer[] = []
  showSuggest = false

  lastSearchText: string | null = null
  cancelledEvent?: KeyboardEvent
  keyIndex = -1
  selectedIndex = -1
  caretPosition: any = { left: 0, top: 0 }

  lastRange: any = null

  items: ProfileModel[] = []

  @Watch('value') onValueChanged(val) {
    if (this.input && this.input.innerHTML !== val) {
      this.input.innerHTML = val
    }
  }

  requestFocus(owner: ProfileModel | null = null) {
    this.input.focus()
    if (owner) {
      this.input.innerHTML = ''
      this.selectItem(owner)
    }
  }

  mounted() {
    this.disposers = [
      autorun(() => {
        const users = [...this.postStore.allComments.map((x) => x.owner!), ...walletStore.recommendUsers]
        this.handleSearch(users)
      }),
    ]
    this.input.innerHTML = this.valueSync
  }

  destroyed() {
    this.disposers.forEach((d) => d())
  }

  @Watch('lastSearchText') onSearchTextChanged() {
    this.handleSearch()
  }

  handleSearch(users: ProfileModel[] = []) {
    const searchText = this.lastSearchText
    if (searchText !== undefined && searchText !== null) {
      users = [...this.postStore.allComments.map((x) => x.owner!), ...walletStore.recommendUsers]
      users = uniqBy(users, (x) => x._id)
      this.items = users.filter((x) => x.username?.toLowerCase().includes(searchText.toLocaleLowerCase()))
    }
  }

  getSelectionStart() {
    return window.getSelection()?.anchorOffset
  }

  getValue() {
    return window.getSelection()?.anchorNode?.textContent
  }

  getLastSearchText(caretIndex, keyIndex) {
    if (keyIndex !== -1) {
      const searchText = this.getValue()?.substring(keyIndex + 1, caretIndex)
      // If there is a space we close the menu
      if ((searchText || searchText === '') && !/\s/.test(searchText)) {
        return searchText
      }
    }
    return null
  }

  onInput(e) {
    this.valueSync = this.input.innerHTML
    if (window.getSelection()?.rangeCount !== 0) {
      this.lastRange = window.getSelection()?.getRangeAt(0)
    }
    const index = this.getSelectionStart()
    if (index != undefined && index >= 0) {
      const keyIndex = this.getValue()?.lastIndexOf('@', index - 1)
      const searchText = (this.lastSearchText = this.getLastSearchText(index, keyIndex))
      // console.log(keyIndex, searchText, this.getValue())
      // const searchText = this.lastSearchText = this.getLastSearchText(index, keyIndex)
      if (keyIndex == undefined || !(keyIndex < 1 || /\s/.test((this.getValue() || '')[keyIndex - 1]))) {
        return false
      }
      if (searchText != null) {
        this.openMenu(keyIndex)
        return true
      }
    }
    this.closeMenu()
    this.emitMentionUsers()
  }

  private emitMentionUsers() {
    const ids = uniq([...(this.input?.getElementsByTagName('a') || [])].map((x) => last(x.href.split('/'))))
    this.$emit('mentionUsers', ids)
  }

  openMenu(keyIndex) {
    this.keyIndex = keyIndex
    this.updateCaretPosition()
    this.selectedIndex = 0
    this.showSuggest = true
  }

  closeMenu() {
    this.showSuggest = false
  }

  selectItem(item) {
    let range
    if (window.getSelection()?.rangeCount === 0) {
      range = this.lastRange
    } else {
      range = window.getSelection()?.getRangeAt(0)
    }
    const searchText = this.lastSearchText || ''
    if (range) {
      const startIndex = range.startOffset - searchText.length - 1
      range.setStart(range.startContainer, startIndex < 0 ? 0 : startIndex)
      range.deleteContents()

      const whitespaceNode = document.createTextNode('\u00A0')
      range.insertNode(whitespaceNode)

      const span = document.createElement('a')
      let userId = ''
      if(item.users_permissions_user) {
        userId = item.users_permissions_user
      } else if (item.google_user) {
        userId = item.google_user
      }
      span.setAttribute('href', `/profile/${userId}`)
      span.setAttribute('contenteditable', 'false')
      span.setAttribute('target', '_blank')

      const textNode = document.createTextNode(item.username)
      span.appendChild(textNode)
      range.insertNode(span)

      range.setStartAfter(whitespaceNode)

      window.getSelection()?.setPosition(whitespaceNode, 1)
    }
    this.closeMenu()
    this.input.focus()
    this.valueSync = this.input.innerHTML
    this.emitMentionUsers()
  }

  insertNewline() {
    const range = window.getSelection()?.getRangeAt(0)
    if (range) {
      range.setStart(range.startContainer, range.startOffset)
      const br = document.createElement('br')
      range.insertNode(br)
      range.setStartAfter(br)
    }
  }

  onKeyDown(e: KeyboardEvent) {
    try {
      if (!this.showSuggest) {
        if (e.key === 'Enter' || e.keyCode === 13) {
          if (e.ctrlKey || e.altKey || e.metaKey) {
            this.insertNewline()
          } else if (!e.shiftKey) {
            this.cancelEvent(e)
            const valid = !!this.input.innerText.replace('\n\n', '').replace('\n', '')
            if (valid) {
              this.$nextTick(() => this.$emit('submit'))
            }
          }
        }
        return true
      }
      if (e.ctrlKey || e.metaKey) {
        switch (e.keyCode) {
          case 66: //ctrl+B or ctrl+b
          case 98:
          case 73: //ctrl+I or ctrl+i
          case 105:
          case 85: //ctrl+U or ctrl+u
          case 117:
            this.cancelEvent(e)
            return
        }
      }
      if (e.key === 'ArrowDown' || e.keyCode === 40) {
        if (this.selectedIndex < this.items.length - 1) {
          this.selectedIndex++
        } else {
          this.selectedIndex = 0
        }
        this.cancelEvent(e)
      }
      if (e.key === 'ArrowUp' || e.keyCode === 38) {
        if (this.selectedIndex > 0) {
          this.selectedIndex--
        } else {
          this.selectedIndex = this.items.length - 1
        }
        this.cancelEvent(e)
      }
      if ((e.key === 'Enter' || e.key === 'Tab' || e.keyCode === 13 || e.keyCode === 9) && this.items.length > 0) {
        this.selectItem(this.items[this.selectedIndex])
        this.cancelEvent(e)
      }
      if (e.key === 'Escape' || e.keyCode === 27) {
        this.closeMenu()
        this.cancelEvent(e)
      }
    } finally {
      this.valueSync = this.input.innerHTML
      this.emitMentionUsers()
    }
  }

  cancelEvent(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
      this.cancelledEvent = e
    }
  }

  updateCaretPosition() {
    const rect = window.getSelection()?.getRangeAt(0).getBoundingClientRect()
    // const inputRect = this.input.getBoundingClientRect()
    // console.log(rect?.top || 0 - inputRect.top + 24)
    this.caretPosition = {
      left: rect?.left || 0,
      top: (rect?.top || 0) + 24,
      height: rect?.height || 0,
    }
  }
}
